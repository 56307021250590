.bg-color {
	background: linear-gradient(
		127deg,
		rgba(234, 200, 250, 0.3) 7%,
		rgba(121, 201, 246, 0.3) 28%,
		rgba(13, 110, 253, 0.3) 59%
	);
}

.float-responsive {
	float: right;
}
.header {
	background-color: #373a40 !important;
	padding: 12px;
}
.footer a,
.header a,
.footer p {
	color: #ffffff;
}

.description-text {
	max-width: 400px;
}

.home-banner {
	position: relative;
	background: url("./assets/banner-bg.png") no-repeat 25% 32%;
	background-size: 150%;
	color: #fff;
	padding: 2rem;
	text-align: center;
	min-height: 550px;
	/* filter: grayscale(50%); */
}

.home-banner::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: linear-gradient(
		30deg,
		rgba(128, 128, 128, 0.1) 0%,
		rgba(128, 128, 128, 0.65) 100%
	);
	z-index: 1;
	pointer-events: none;
}
.home-banner-title {
	font-size: 3.5rem !important;
	-webkit-text-stroke: 0.1px rgba(0, 0, 0, 0.7);
}
.home-banner-content {
	position: relative;
	padding: 2rem;
	z-index: 2;
	max-width: 700px;
	text-align: left;
}
.login-button-mobile {
	display: none !important;
}
.login-button-desktop {
	margin-left: 15px;
}
.highlight {
	background: linear-gradient(90deg, #3556ff, #994aff);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
}
.data-numbers {
	color: #5fc8ff;
	font-weight: 900;
	padding-right: 12px;
	font-size: 1.5rem;
}

.image-alignment {
	text-align: right;
}
.subscribe-bg {
	color: #fff;
	text-align: center;
	background-image: url("./assets/subscribe-bg.png");
	background-repeat: no-repeat;
	position: relative;
	background-size: cover;
	background-position: center;
}
.fancy-bg {
	background: linear-gradient(135deg, #38bdf8, #ffffff);
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
}

.fancy-bg::before,
.fancy-bg::after {
	content: "";
	position: absolute;
	border-radius: 50%;
	opacity: 0.3;
	z-index: 1;
	pointer-events: none;
	mix-blend-mode: overlay;
}

/* Large circle shape */
.fancy-bg::before {
	width: 600px;
	height: 600px;
	background: rgba(41, 24, 86, 0.5); /* Semi-transparent purple */
	top: 100px;
	left: 100px;
}

/* Small circle shape */
.fancy-bg::after {
	width: 400px;
	height: 400px;
	background: rgba(137, 212, 207, 0.5); /* Semi-transparent teal */
	bottom: -120px;
	right: -80px;
}

.fancy-bg .triangle {
	position: absolute;
	top: 20%;
	left: 50%;
	width: 0;
	height: 0;
	border-left: 150px solid transparent;
	border-right: 150px solid transparent;
	border-bottom: 260px solid rgba(255, 255, 255, 0.2); /* Semi-transparent white */
	transform: translate(-50%, -50%);
	z-index: 1;
}
.fancy-bg > div {
	position: relative;
	z-index: 2;
}

.custom-grid-item {
	text-align: left;
}

.job-card {
	transition: transform 0.2s ease, box-shadow 0.2s ease;
	cursor: pointer;
}

.job-card:hover {
	transform: scale(1.02);
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.image-column {
	text-align: center;
}
.image-column img {
	max-height: 400px;
	width: auto;
}

.card-rounded {
	border-radius: 5px;
	overflow: hidden; /* Ensures child content respects the border radius */
}
@media (min-width: 768px) {
	.custom-grid-content {
		max-width: 450px;
		margin-left: auto;
	}
	.image-column {
		text-align: right;
	}
}

@media (min-width: 1200px) {
	.home-banner {
		background-size: 100%;
	}
}

@media (max-width: 767px) {
	.home-banner-content {
		max-width: none;
	}
	.home-banner {
		background-size: 250%;
	}
	.image-alignment {
		text-align: center;
	}
	.custom-grid-content {
		max-width: 100%;
	}

	.float-responsive {
		float: left;
	}
	.home-banner-title {
		font-size: 2rem !important;
	}
	.home-banner-subtitle {
		font-size: 1.5rem !important;
	}
	.login-button-mobile {
		display: inline-block !important;
	}
}

@media (max-width: 576px) {
	.description-text {
		max-width: 100%;
	}
	.home-banner {
		background-size: 250%;
	}
}
@media (max-width: 480px) {
	.home-banner {
		background: url("./assets/banner-bg.png") no-repeat 40% 0%;
		background-size: 360%;
	}
}
